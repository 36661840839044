@import url("https://use.typekit.net/iua7raw.css");

* {
    box-sizing: border-box;
}

html, body {
    font-family: aktiv-grotesk, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}

a, button.link {
    text-decoration: none;
    color: #2200CC;
}

a:hover, button.link:hover {
    text-decoration: underline;
}

label {
    display: inline-block;
    width: 100%;
    max-width: 200px;

    padding: 8px 16px;
    margin: 4px;

    vertical-align: top;
    text-align: left;
}

input,
select,
textarea,
button {
    display: inline-block;

    padding: 8px 16px;
    margin: 4px;

    border: 1px solid #B2B2B2;
    border-radius: 3px;
    background-color: transparent;

    vertical-align: top;
}

button {
    padding: 16px 32px;

    text-transform: uppercase;
    font-weight: 600;

    color: #009fe3;
    border: 1px solid #009fe3;

    background-color: #fff;

    cursor: pointer;
}

button:hover {
    color: #fff;
    border-color: #fff;
    background-color: #009fe3;
}

input:not([type="button"]),
select,
textarea {
    width: 100%;
    max-width: 300px;
}

button.link {
    display: inline;

    padding: 0;
    margin: 0;

    border: 0;
}

/* Styles for ReactPasswordStrength component */
.ReactPasswordStrength input {
    margin: 0;
    padding: 8px 16px;

    font-size: 100%;
}

.ReactPasswordStrength {
    display: inline-block;

    margin: 4px;

    width: 100%;
    max-width: 300px;

    border: 1px solid #B2B2B2;
    border-radius: 3px;
    background-color: transparent;

    font-size: 100%;
}

.ReactPasswordStrength-strength-desc {
    top: unset;
    bottom: 1px;

    width: 25%;

    padding-bottom: 2px;

    font-size: 80%;
}

.ReactPasswordStrength-strength-bar {
    width: 100%;
}
